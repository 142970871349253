@import "app";

.btn-header {
	@extend .mb-2, .mb-md-0, .w-100;
}

.img-custom-placeholader {
	width: 80px;
	height: 80px;
}

#header-profile-option {
	min-width: 19%;
}