@import "~bootstrap/scss/functions";

// override variables
$light: #f5f6f8;
$primary: #217bba;
$warning: #f6aa1e;
$danger: #ce3232;
$success: #2b7e32;
$info: #32a3dc;

$enable-negative-margins: true;

@import "~@hashimukh/stardust/build/esm/res/styles/stardust";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

.clamp {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
};

$utilities: map-merge($utilities, (
	"object-fit": (
		property: object-fit,
		class: fit,
		values: cover contain,
	),
	"line-clamp": (
		property: -webkit-line-clamp line-clamp,
		class: clamp,
		responsive: true,
		values: 1 2 3 4,
	),
	"width": map-merge(
		map-get($utilities, "width"),
		(
			values: map-merge(
				map-get(map-get($utilities, "width"), "values"),
				(min: min-content, max: max-content, fit: fit-content)
			)
		)
	),
	"height": map-merge(
		map-get($utilities, "height"),
		(
			values: map-merge(
				map-get(map-get($utilities, "height"), "values"),
				(min: min-content, max: max-content, fit: fit-content)
			)
		)
	),
    "overflow-y": (
        property: overflow-y,
        values: auto hidden visible scroll,
    ),
    "overflow-x": (
        property: overflow-x,
        values: auto hidden visible scroll,
    )
));

// Configuration
@import "~bootstrap/scss/mixins";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

.text-feedback {
	margin-top: $form-feedback-margin-top;
	font-size: $form-feedback-font-size;
	font-style: $form-feedback-font-style;
}

.text-invalid {
	@extend .text-feedback;
	color: $form-feedback-invalid-color;
}

.text-valid {
	@extend .text-feedback;
	color: $form-feedback-valid-color;
}

.title-overlay {
	&:after {
		content: "";
		position: absolute;
		left: 0; top: 0;
		width: 100%; height: 100%;
		display: inline-block;
		background: linear-gradient(180deg, rgba($white, 0) 40%, rgba($black, .8) 100%);
	}
}

.carousel-img-container {
	position: relative;
	display: inline-block;

	&[data-clean="false"] {
		@extend .title-overlay;
	}

	&:not([data-clean]) {
		@extend .title-overlay;
	}

	img {
		@extend .fit-cover;
	}
}

.carousel-image {
	@extend .fit-cover;
}