@import "app";
@import "./blog-post-content.scss";

.content-post {
	img {
		@extend .rounded, .mx-auto, .mw-100;
	}
}

.progress-copyable-text {
	height: $input-height-sm !important;

	@include media-breakpoint-down(sm) {
		height: $input-height-sm !important;
	}

	@include media-breakpoint-up(lg) {
		height: $input-height-lg !important;
	}
}