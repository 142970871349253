.avt-img {
    &[data-editable="true"] {
        &:hover {
            filter: brightness(80%);
        }

        &:active {
            filter: brightness(60%);
        }
    }

    &[data-disabled="true"] {
        filter: contrast(50%) brightness(100%);
    }
}

.avt-img-placeholder {
    height: 192px;
    width: 192px;
}