@import "./app.scss";
@import "./blog-post-content.scss";

.post-headline {
	@extend .clamp, .clamp-2;
}

.post-subheadline {
	@extend .clamp, .clamp-2;
}

.post-cover {
	p {
		@extend .mb-0, .ratio, .ratio-1x1;
	}

	img {
		@extend .fit-cover, .rounded;
	}

	.carousel-img-container {
		img {
			@extend .carousel-image;
		}
	}
}

.post-preview {
	@extend .py-3, .px-3;

	.post-content {
		max-height: 192px;
	}

	&[data-horizontal="true"] {
		@extend .px-2, .px-sm-3;

		.post {
			@extend .g-sm-3, .g-2;
		}

		.post-content {
			@extend .col, .order-1;

			p:last-child {
				@extend .mb-0;
			}
		}

		.post-subheadline {
			@extend .clamp-1, .clamp-sm-3;
		}

		@include media-breakpoint-down(sm) {
			.post-headline {
				font-size: map-get($map: $font-sizes, $key: 5);
			}
		}

		.post-cover {
			@extend .col, .mb-0;
			width: 128px;
			min-width: 128px;
			max-width: 128px;

			@include media-breakpoint-up(sm) {
				width: 256px;
				min-width: 256px;
				max-width: 256px;

				p {
					--bs-aspect-ratio: 75%;
				}
			}
		}
	}
}