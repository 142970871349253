@import "app";

.itm-cheap {
	min-height: 48px;
}

.itm-cheap:last-of-type {
	.itm-divider {
		@extend .d-none
	}
}